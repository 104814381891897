
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Mosaic } from '@/lib/mv-web';
import { SET_SHOW_LEGEND } from '@/store/mutation-types';

@Component({
})
export default class Legend extends Vue {
  @Prop()
  public url!: string;

  private close() {
    this.$store.commit(SET_SHOW_LEGEND, false);
  }
}
