
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Mosaic } from '@/lib/mv-web';

@Component({
})
export default class SheetInfo extends Vue {
  @Prop()
  public sheetProperties!: any;
  // the sheet properties are retrieved from a geojson file; they are dynamic.

  protected get title() {
    return (this.sheetProperties.name && this.sheetProperties.name.trim()) || this.sheetProperties.map_index;
  }
}
