
import _ from 'lodash';
import Vue from 'vue';
import MosaicMap from './Map.vue';
import SheetInfo from './SheetInfo.vue';
import MapLegend from './Legend.vue';
import { Component, Prop } from 'vue-property-decorator';
import { FileResponse, Mosaic, ProductDetails } from '@/lib/mv-web';
import { SET_CURRENT_MOSAIC } from '@/store/mutation-types';
import { mapGetters, mapState } from 'vuex';
import { FileDownloadErrorHandler } from '@/lib/helpers';

@Component({
  components: { MapLegend, MosaicMap, SheetInfo },
  computed: {
    ...mapState({
      showLegend: (state: any) => state.mosaic.showLegend,
      showMetadata: (state: any) => state.mosaic.showMetadata,
    }),
    ...mapGetters(['hasLegend', 'hasMetadata']),
  },
})
export default class MosaicViewer extends Vue {
  @Prop({ default: null })
  public baseUrl!: string | null;

  @Prop()
  public gid!: string;

  public get mosaic() {
    // Note: we could fetch the mosaic directly from the router via a prop function
    return this.$store.getters.mosaicByGuid(this.gid);
  }

  protected onDownloadLegend() {
    const fileName = 'legend.png';
    this.$store.state.client.attachment(this.gid, 5)
      .then((f: FileResponse) => {
      const fileURL = window.URL.createObjectURL(f.data);
      const fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute('download', f.fileName || fileName);
      const dlEl = document.body.appendChild(fileLink);
      fileLink.click();
    }).catch( FileDownloadErrorHandler(this, fileName) );
  }
}
