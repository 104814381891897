
import _, { isString } from 'lodash';
import Vue from 'vue';
import axios from 'axios';
import { Feature, ImageTile, MapBrowserEvent, Tile, VectorTile } from 'ol';
import { defaults as defaultMapControls, FullScreen, Attribution } from 'ol/control';
import { FeatureLike } from 'ol/Feature';
import { GeoJSON } from 'ol/format';
import { Polygon } from 'ol/geom';
import { Style } from 'ol/style';
import * as olProj from 'ol/proj';
import { pointerMove } from 'ol/events/condition';
import { default as VueLayers } from 'vuelayers';
import { Component, Prop, Watch, Emit, Inject } from 'vue-property-decorator';
import { Mosaic, Bounds, ProductDetails, MosaicClient, FileResponse } from '@/lib/mv-web';
import { extendCoordinates } from 'ol/extent';
import defaultStyles from '@/lib/ol-custom-ext/DefaultMapStyles';
import basemaps from '@/lib/ol-custom-ext/basemaps';
import WatermarkControl from '@/lib/ol-custom-ext/WatermarkControl';
// import InfoDialogControl from '@/lib/ol-custom-ext/InfoDialogControl';
// import Dummy from '@/lib/ol-custom-ext/dummy.vue';

// import { SET_INDEXMAP_SELECTION } from '@/store/map';
import { FileDownloadErrorHandler } from '@/lib/helpers';
import { SET_CURRENT } from '@/store/mutation-types';
import TileState from 'ol/TileState';

Vue.use(VueLayers);

type SymbolizerFactory = () => (feature: any, resolution: number) => any;
type StyleSelector = (feature: Feature) => Style|null;

@Component({
})
export default class MosaicMap extends Vue {
  @Prop({ default: null })
  public baseUrl!: string | null;

  @Prop()
  public mosaic!: Mosaic;

  @Prop({default: false})
  public showLegend!: boolean;

  @Prop({default: false})
  public showMetadata!: boolean;

  public zoom: number = 2;

  public showDebug: boolean = false;

  protected highlightedFeatureId: string | number | null = null;
  protected highlightedFeature: FeatureLike | null = null;

  public get tileUrl(): string {
    return `${this.rootUrl}/Tiles/${this.gid}/{z}/{x}/{y}.png`;
  }


  public get footprintTileUrl(): string {
    return `${this.rootUrl}/Tiles/${this.gid}/Sources/{z}/{x}/{y}.mvt`;
  }

  public zoomToMosaic() {
    if (this.mosaic.extent === undefined ) {
      return;
    }
    const coordinates = this.normalizeExtent(this.mosaic.extent,  'EPSG:4326', 'EPSG:3857');
    if ( coordinates !== null ) {
      const v = this.$refs.view as any;
      v.fit(coordinates);
    }
  }

  public get minZoom() {
    if (this.mosaic.minZoom && this.mosaic.maxZoom) {
      if (this.mosaic.minZoom === this.mosaic.maxZoom) {
        // we assume that someone forgot the set the maxzoom
        return 0;
      }
      return Math.min(this.mosaic.minZoom, this.mosaic.maxZoom);
    }
    return this.mosaic.minZoom || 0;
  }

  public get maxZoom() {
    if (this.mosaic.minZoom && this.mosaic.maxZoom) {
      if (this.mosaic.minZoom === this.mosaic.maxZoom) {
        if ( this.mosaic.maxZoom === 0 ) {
          // we assume that someone forgot the set the maxzoom
          return 24;
        }
          // we assume that someone forgot the set the minzoom
        return this.mosaic.maxZoom;
      }
      return Math.min(this.mosaic.minZoom, this.mosaic.maxZoom);
    }
    return this.mosaic.maxZoom || 24;
  }

  public get gid() { return this.mosaic.guid; }

  public get rootUrl(): string {
    let baseUrl = this.baseUrl;
    if (!baseUrl) {
      baseUrl = `${this.$store.state.apiUrls.mapvault}`;
    }
    return baseUrl;
  }

  protected get basemap() { return basemaps[this.$store.state.basemap]; }

  protected get mapControls() {
    return defaultMapControls().extend([
      new Attribution({collapsed: true}),
      new FullScreen(),
      new WatermarkControl({}),
    ]);
  }

  protected get selectCondition() { return pointerMove; }

  protected footprintSymbolizer = ( feature: Feature) => {
    try {
        if (feature.get('fid') === this.getCurrentHighlightedFootprintId()) {
          return defaultStyles.overlay;
        }
        return defaultStyles.standard;
    } catch (e) {
      // If there is any problem, fail over to returning null
    }
    return null;
  }
  protected getCurrentHighlightedFootprintId() { return this.highlightedFeatureId; }

  protected loadMosaicTile(tile: ImageTile, src: string) {
    // We need to use axios to make sure to use the authentication interceptor
    tile.setState(TileState.LOADING);
    axios.get(src, {
      responseType: 'blob',
    }).then( (response: FileResponse) => {
      const reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onloadend = (ev: ProgressEvent<FileReader>) => {
        (tile.getImage() as any).src = reader.result;
        tile.setState(TileState.LOADED);
      };
    }).catch( (err: any) => {
      tile.setState(TileState.ERROR);
    });
  }


  protected loadFootprintTile(tile: VectorTile, src: string) {
    if (!this.gid) {
      return;
    }
    tile.setLoader( (extent, resolution, projection) => {
      tile.setState(TileState.LOADING);
      axios.get(src, {
        responseType: 'arraybuffer',
      }).then( (response: any ) => {
        if (response.status === 200) {
          return response.data;
        }
        throw response;
      })
        .then( (data: ArrayBuffer) => {
          const format = tile.getFormat(); // ol/format/MVT configured as source format
          const features = format.readFeatures( data, {
            extent,
            featureProjection: projection,
          });
          /* there is a bug in OpenLayers typings decalring setFeatures as Feature<Geometry> instead of FeatureLike
              Note: adjust the OL typings to avoid errors from linter or intellisense.
              File: @/types/ol/VectorTile.d.ts
            */
          tile.setFeatures(features);
        })
        .catch(() => {
          console.log('error');
        });
    });
  }

protected getProperties(item: Mosaic) {
    const props: any = _.pickBy(item, (v, k) => k !== 'extent');
    const details = this.$store.state.details.find( (d: ProductDetails) => d.guid === item.guid);
    if (!!details) {
      props.authorized = details.authorized;
    }
    return props;
  }

  protected extentToCoordinates(extent: Bounds, srcSrs?: olProj.ProjectionLike, tgtSrs?: olProj.ProjectionLike) {
    const w = extent.xMax - extent.xMin;
    const h = extent.yMax - extent.yMin;
    if (w < 359 || h < 120) {
      const m = extent.xMax + extent.xMin;
      if (m > 360) {
          // midpoint past the anti-meridian; warp on earth westward
          extent.xMin -= 360;
          extent.xMax -= 360;
      }
      if (m < -360) {
          // midpoint past the anti-meridian; warp on earth eastward
          extent.xMin += 360;
          extent.xMax += 360;
      }
    }
    if (srcSrs !== undefined && tgtSrs !== undefined && srcSrs !== tgtSrs) {
      return [[
          // olProj.transform([extent.xMin, extent.yMin], 'EPSG:4326', 'EPSG:3857'),
          olProj.transform([extent.xMin, extent.yMin], srcSrs, tgtSrs),
          olProj.transform([extent.xMax, extent.yMin], srcSrs, tgtSrs),
          olProj.transform([extent.xMax, extent.yMax], srcSrs, tgtSrs),
          olProj.transform([extent.xMin, extent.yMax], srcSrs, tgtSrs),
          olProj.transform([extent.xMin, extent.yMin], srcSrs, tgtSrs),
        ]];
    }
    return [[
        [extent.xMin, extent.yMin],
        [extent.xMax, extent.yMin],
        [extent.xMax, extent.yMax],
        [extent.xMin, extent.yMax],
        [extent.xMin, extent.yMin],
      ]];
  }

  protected normalizeExtent(extent: Bounds, srcSrs?: olProj.ProjectionLike, tgtSrs?: olProj.ProjectionLike) {
    const w = extent.xMax - extent.xMin;
    const h = extent.yMax - extent.yMin;
    if (w < 359 || h < 120) {
      const m = extent.xMax + extent.xMin;
      if (m > 360) {
          // midpoint past the anti-meridian; warp on earth westward
          extent.xMin -= 360;
          extent.xMax -= 360;
      }
      if (m < -360) {
          // midpoint past the anti-meridian; warp on earth eastward
          extent.xMin += 360;
          extent.xMax += 360;
      }
    }
    if (srcSrs !== undefined && tgtSrs !== undefined && srcSrs !== tgtSrs) {
      return [
        ...olProj.transform([extent.xMin, extent.yMin], srcSrs, tgtSrs),
        ...olProj.transform([extent.xMax, extent.yMax], srcSrs, tgtSrs),
      ];
    }
    return [
      extent.xMin, extent.yMin,
      extent.xMax, extent.yMax,
    ];
  }

  private onPointerMove(evt: MapBrowserEvent) {
    try {
      // the "pixel" property does not work, but the "pixel_" does; there may be an error in the type registration
      const candidates = evt.map.getFeaturesAtPixel((evt as any).pixel_, { checkWrapped: true });
      if (candidates.length === 0) {
        this.highlightedFeatureId = null;
        this.highlightedFeature = null;
        return;
      }
      const topCandidate = candidates.sort((one: FeatureLike, another: FeatureLike) => {
        const gOne = one.getGeometry() as Polygon;
        if (gOne === null) {
          return 1;
        }
        const gAnother = another.getGeometry() as Polygon;
        if (gAnother === null) {
          return -1;
        }
        return gOne.getArea() - gAnother.getArea();
      })[0];
      this.highlightedFeatureId = topCandidate.get('fid');
      this.highlightedFeature = topCandidate;
    } catch (TypeError) {
      // These occur upon first entry into the window; just swallow them.
    }
  }

  @Watch('highlightedFeatureId')
  private onSelectionChanged( newSelection: string | number | null, oldSelection: string | number | null) {
    if (!_.isEqual(newSelection, oldSelection)) {
      // triggers a redraw of the designated layer
      (this.$refs.footprints as any).$layer.changed();
    }
  }

  // private onClick(evt: MapBrowserEvent) {
  //   // if the user clicks, they must have moved to the feature of interest first,
  //   // so the pointer move will have acquired the guid of interest
  //   if (this.highlightedFeatureId !== null) {
  //     this.$store.commit(SET_CURRENT, this.highlightedFeatureId);
  //   }
  // }
}
